import React,{useEffect, useState} from 'react'
import pays from '../data/pays'
const PaySystems = () => {
   const [locationId,setLocationId] = useState('all')
   const fetchUserCountry = async () => {
      const searchParams = new URLSearchParams(window.location.search)

      if(searchParams.get('location')){
         if(pays.find(item=>item.name===searchParams.get('location'))){
            setLocationId(searchParams.get('location')??'en')

         }
      }else{

         fetch('https://api.country.is/')
         .then(res => res.json())
         .then(result=>{
            if(result.country){
               let c = result.country.toLowerCase().slice(0,2)
               if(pays.find(item=>item.name===c)){
                  setLocationId(c)

               }
            }

         })
         .catch(e => console.log(e))
      }
   
   }
   useEffect(()=>{
      fetchUserCountry()
   },[])
   return (
      <nav className='flex-1'>
         <ul className={`flex   item-center justify-end flex-wrap pl-[10px] mr-[-10px]`}>
            {
               pays.find(item=>item.name === locationId)?.imgs.map((item,index)=>(
                  <li className={`py-[10px] md:px-[10px] px-[5px] relative `} key={index}>
                     <img className={`md:max-w-none ${item.includes('visa.svg')&&'max-w-[69px]'} ${item.includes('mastercard.svg')&&'max-w-[28px]'} ${item.includes('mastercard.svg')&&'max-w-[28px]'} ${item.includes('mir.svg')&&'max-w-[59px] sm:max-w-[79px]'} ${item.includes('webmoney.svg')&&'max-w-[95px]'} ${item.includes('gpay.svg')&&'max-w-[54px]'}   h-auto w-auto`}  src={item} alt={item.replace('.svg','').split('/')[item.replace('.svg','').split('/').length-1]} />
                  </li>
               ))
            }
         </ul>
      </nav>
   )
}

export default PaySystems