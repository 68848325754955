'use client'
 
import { useEffect, useState } from 'react'
 
export default function useWindowSize() {
  const [desk,setDesk] = useState(true)
  const [slideH,setSlideH] = useState('auto')

  const resFunc = () =>{
    // setTimeout(() => {
    //   let slides = document.querySelectorAll('.slideCnt')
    //   for (let index = 0; index < slides.length; index++) {
    //     const slide = slides[index];
    //     if(index!==0){
    //       if(slide.clientHeight>slides[index-1].clientHeight){
    //         setSlideH(20+slide.clientHeight+'px')
    //       }
    //     }
        
    //   }
    // }, 200);
    

    if(window.innerWidth>767){
      setDesk(true)
    }else{
      setDesk(false)
    }
  }
  useEffect(() => {
    resFunc()
    window.addEventListener('resize',resFunc)
    
    return ()=> window.removeEventListener('resize',resFunc)
  },[])

  
  return {desk,slideH}
}