
 const langs = [
   {
      id:1,
      name:'uz',
      img:'/img/UZ.png'
   },
   {
      id:2,
      name:'fr',
      img:'/img/FR.png'
   },
   {
      id:3,
      name:'en',
      img:'/img/br.png'
   },
   // {
   //    id:4,
   //    name:'so',
   //    img:'/img/SO.png'
   // },
   {
      id:5,
      name:'ar',
      img:'/img/AR.png'
   },
   {
      id:6,
      name:'ru',
      img:'/img/RU.png'
   },
   {
      id:7,
      name:'pt',
      img:'/img/PT.png'
   },
   {
      id:8,
      name:'bd',
      img:'/img/BD.png'
   }
   
]

export default langs






