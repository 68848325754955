 const welcome = {
   title:[
      {
         id:1,
         locale:'uz',
         text:`<span class='text-[var(--green)]'>Linebetdan </span><span>bonusga ega bo'ling</span>`
      },
      {
         id:2,
         locale:'fr',
         text:`<span>Recevez un bonus de la part de </span><span class='text-[var(--green)]'>Linebet</span>`

      },
      {
         id:3,
         locale:'en',
         text:`<span>Get <span class='text-[var(--green)]'>Linebet</span> bonus</span>`

      },
      {
         id:4,
         locale:'so',
         text:`<span>Ka hel gunno <span class='text-[var(--green)]'>Linebet</span></span>`

      },
      {
         id:5,
         locale:'ar',
         text:`<span class='text-[var(--green)]'> Linebet </span></br><span>احصل على مكافأة من </span>`

      },
      {
         id:6,
         locale:'ru',
         text:`<span>Получи бонус от </span><span class='text-[var(--green)]'>Linebet</span>`

      },
      {
         id:7,
         locale:'pt',
         text:`<span>Receba Bônus da </span><span class='text-[var(--green)]'>Linebet</span>`

      },
      {
         id:8,
         locale:'bd',
         text:`<span class='text-[var(--green)] font-[600]'>লাইনবেটে</span><span class='font-[600]'> বোনাস লুফে নিন</span>`

      }
      
   ],
   left:[
      {
         id:1, 
         locale:'uz',
         text:`<span class=' block max-w-[420px]'>Birinchi depozitga <span class='text-[var(--green)] font-[600] '> <span class='text-nowrap'>1 300 000 </span> UZS gacha </span>bonusga ega bo'ling</span>`
      },
      {
         id:2,
         locale:'fr',
         text:`<span>Recevez <span class='text-[var(--green)] font-[600]'> jusqu'à 60.000 Fr cfa</span><br/> sur votre premier dépôt</span>`

      },
      {
         id:3,
         locale:'en',
         text:`<span>Get <span class='text-[var(--green)] font-[600]'>100$ </span><br/> on the first deposit</span>`

      },
      {
         id:4,
         locale:'so',
         text:`<span>Qaado ilaa <span class='text-[var(--green)] font-[600]'>100$ </span><br/> deebaajigaaga ugu horeeya</span>`

      },
      {
         id:5,
         locale:'ar',
         text:`
         <span>احصل على ما يصل</span><span class=' directionRtl text-[var(--green)] font-[600]'>  إلى 100</span><span> دولار </span>
         <p>
          على إيداعك الأول
         </p>
         `

      },
      //text:`<span class=' directionRtl '><br/> </span>`
      {
         id:6,
         locale:'ru',
         text:`<span>Забери <span class='text-[var(--green)] font-[600]'>до 100$ </span><br/> на первый депозит</span>`

      },
      {
         id:7,
         locale:'pt',
         text:`<span>Receba <span class='text-[var(--green)] font-[600]'>até$ 100  </span><br/> no seu primeiro
         depósito</span>`

      },
      {
         id:8,
         locale:'bd',
         text:`<span>প্রথম ডিপোজিটে পাচ্ছেন  <span class='text-[var(--green)] font-[600]'> ১০,০০০৳ </span> পর্যন্ত</span>`

      }
      
   ],
   right:[
      {
         id:1,
         locale:'uz',
         text:`<span class='text-[var(--green)]  font-[600]'>Qulay ro'yxatdan o'tish <br/><span class='text-[var(--black)]  font-[400]'>va </span>mablag'ni tez yechib olish.</span>`
      },
      {
         id:2,
         locale:'fr',
         text:`<span class='text-[var(--green)]  font-[600]'>Inscription simple <br/><span class='text-[var(--black)]  font-[400]'>et </span>retrait rapide</span>`

      },
      {
         id:3,
         locale:'en',
         text:`<span class='text-[var(--green)]  font-[600]'>Simple registration. <br/><span class='text-[var(--black)] font-[400]'></span>Fast withdrawals.</span>`

      },
      {
         id:4,
         locale:'so',
         text:`<span class='text-[var(--green)]  font-[600]'>Diiwaangelin fudud iyo <br/><span class='text-[var(--black)]  font-[400]'></span>la bixid degdeg ah</span>`

      },
      {
         id:5,
         locale:'ar',
         text:`<span class='text-[var(--green)]  font-[600]'>سهولة التسجيل والسحب السريع</span>`

      },
      {
         id:6,
         locale:'ru',
         text:`<span class='text-[var(--green)]  font-[600]'>Простая <br/> регистрация</span><br/> и <span class='text-[var(--green)] font-[600]'>быстрый вывод </span>`

      },
      {
         id:7,
         locale:'pt',
         text:`<span class='text-[var(--green)]  font-[600]'>Registro simples  </span><br/><span class=' font-[400]'> e </span><span class='text-[var(--green)]  font-[600]'>retirada rápida  </span>`

      },
      {
         id:8,
         locale:'bd',
         text:`<span class='text-[var(--green)]  font-[600]'>সহজ রেজিস্ট্রেশন</span><span > এবং</span><span class='text-[var(--green)]  font-[600]'> দ্রুত উইথড্র</span>`

      }
      
   ],
   btn:[
      {
         id:1,
         locale:'uz',
         text:`Bonus olish`
      },
      {
         id:2,
         locale:'fr',
         text:`Recevez un bonus`

      },
      {
         id:3,
         locale:'en',
         text:`Get bonus`

      },
      {
         id:4,
         locale:'so',
         text:`Ka hel gunno Linebet`

      },
      {
         id:5,
         locale:'ar',
         text:`احصل على مكافأة`

      },
      {
         id:6,
         locale:'ru',
         text:`Получить бонус`

      },
      {
         id:7,
         locale:'pt',
         text:`Obter bônus`

      },
      {
         id:8,
         locale:'bd',
         text:`প্রমোকোড ব্যবহার করে বোনাস বুঝে নিন।`

      }
      
   ]
}
   


export default welcome


