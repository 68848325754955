 const bonus = {
   copy:[{
      id:1,
      locale:'uz',
      text:`Nusxalangan`
   },
   {
      id:2,
      locale:'fr',
      text:`Copié`

   },
   {
      id:3,
      locale:'en',
      text:`Copied`

   },
   {
      id:4,
      locale:'so',
      text:`Naqliyay`

   },
   {
      id:5,
      locale:'ar',
      text:`نسخ`

   },
   {
      id:6,
      locale:'ru',
      text:`Скопировано`

   },
   {
      id:7,
      locale:'pt',
      text:`Copiado`

   },
   {
      id:8,
      locale:'bd',
      text:`কপি করা হয়েছে।`

   }],

   btn:[
      {
         id:1,
         locale:'uz',
         text:`Bonus olish`
      },
      {
         id:2,
         locale:'fr',
         text:`Recevez un bonus`

      },
      {
         id:3,
         locale:'en',
         text:`Get bonus`

      },
      {
         id:4,
         locale:'so',
         text:`Faaido hel`

      },
      {
         id:5,
         locale:'ar',
         text:`احصل على مكافأة`

      },
      {
         id:6,
         locale:'ru',
         text:`Получить бонус`

      },
      {
         id:7,
         locale:'pt',
         text:`Obter bônus`

      },
      {
         id:8,
         locale:'bd',
         text:`প্রমোকোড ব্যবহার করে বোনাস বুঝে নিন।`

      }
      
   ]
}
   

export default bonus


