import React, { useEffect, useState } from 'react'
import Langs from './Langs'

import langs from '../data/langs'
const Menu = ({locale,setLocale,setLoading,setLangsCh}) => {
   const [ttout,setTtout] = useState(null)

   const [active,setActive] = useState(false)
   const setLangF = (l) =>{
      setLangsCh(true)
      if(l!==locale){
         setLocale(l)
         setLoading(true)
         window.localStorage.setItem('lang',l)
      }
      
   }
   useEffect(()=>{
      window.addEventListener('click',()=>{
         setActive(false)
      })
   },[])
   useEffect(()=>{
      if(!ttout){
         setTtout(
            setTimeout(() => {
               setLoading(false)
               setTtout(null)
               }, 1000)
         )
         
      }
      
   },[locale])
   const handleClick = (e) =>{
      e.stopPropagation()
      setActive(!active)
   }
   return (
      <nav className={`text-[16px] relative font-[700]`}>
         <button onClick={handleClick} className='w-[60px]  flex justify-end items-center uppercase'>
            {locale}
            <img className={`ml-[6px] ${active&&'rotate-180'} transition-transform duration-200`} width={20} height={20} src="/img/arr.svg" alt="arr" />
         </button>
         <ul className={`${!active&&'opacity-0 pointer-events-none'} overflow-hidden transition-opacity duration-200 absolute z-[5] right-0 top-[43px] menulangs overflow-hidden w-[148px] flex items-start justify-start flex-wrap`}>
            {
               langs.map((el,index)=>(
                  <Langs locale={locale} setLangF={setLangF} index={index} key={el.id} lang={el}/>
               ))
            }
         </ul>
      </nav>
   )
}

export default Menu