 const slider = 
   [
      {
         id:1,
         name:'games',
         title:[
            {
               id:1,
               locale:'uz',
               text:`O'yinlar`
            },
            {
               id:2,
               locale:'fr',
               text:`Jeux`
      
            },
            {
               id:3,
               locale:'en',
               text:`Games`
      
            },
            {
               id:4,
               locale:'so',
               text:`Ciyaaraha`
      
            },
            {
               id:5,
               locale:'ar',
               text:`ألعاب`
      
            },
            {
               id:6,
               locale:'ru',
               text:`Игры`
      
            },
            {
               id:7,
               locale:'pt',
               text:`Jogos`
      
            },
            {
               id:8,
               locale:'bd',
               text:`গেমস`
      
            }
            
         ],
         descr:[
            {
               id:1,
               locale:'uz',
               text:`<span class='liSpan'><span class='text-[var(--green)] font-[600]'>Oddiy </span>ro'yxatdan o'tish.</span></br>
               <span class='liSpan'>Pulni<span class='text-[var(--green)] font-[600]'> tez </span> yechib olish.</span></br>
               <span class='liSpan'><span class='text-[var(--green)] font-[600]'>Sifatli </span>qo'llab-quvvatlash xizmati.</span>`
            },
            {
               id:2,
               locale:'fr',
               text:`<span class='liSpan'>Inscription <span class='text-[var(--green)] font-[600]'>simple. </span></span></br>
               <span class='liSpan'>Retrait<span class='text-[var(--green)] font-[600]'> rapide. </span></span></br>
               <span class='liSpan'><span class='text-[var(--green)] font-[600]'>Service </span> client fiable et disponible 7 j sur 7 j, 24 h sur 24 h.</span>`
            },
            {
               id:3,
               locale:'en',
               text:`<span class='liSpan'><span class='text-[var(--green)] font-[600]'>Simple  </span>registration.</span></br>
               <span class='liSpan'><span class='text-[var(--green)] font-[600]'>Fast  </span>withdrawals.</span></br>
               <span class='liSpan'><span class='text-[var(--green)] font-[600]'>Reliable  </span>support team.</span>`
            },
            {
               id:4,
               locale:'so',
               text:`<span>Si fudud isku diwan gali, si degdega lacagaha ula bax
               kooxda caawinta ee lagu kalsoon yahay</span>`
            },
            {
               id:5,
               locale:'ar',
               text:`
               <div class='directionRtl flex items-center'>
                  <p>خدمة</p>
                  <p class='mr-[6px]'>الدعم</p>
                  <p class='text-[var(--green)] font-[600] mx-[6px]'>المخلص</p>
                  <p class=''>تسجيل</p>
                  <p class='text-[var(--green)] font-[600] mx-[6px]'>سهل.</p>
                  <p class=''>سحب</p>
                  <p class='text-[var(--green)] font-[600] mx-[6px]'>سريع  </p>
                  <p class=''>للأموال. </p>
               </div>
               `

            },

            // <p class='text-[var(--green)] font-[600]'>  </p>
            //       <p class=''>
            //         
            //       <p class='text-[var(--green)] font-[600]'>  </p>
            //        
            //       <p class='text-[var(--green)] font-[600]'>  </p>
            //       
            //       </p>
            {
               id:6,
               locale:'ru',
               text:`<span class='liSpan'><span class='text-[var(--green)] font-[600]'>Простая </span>регистрация.</span></br>
               <span class='liSpan'><span class='text-[var(--green)] font-[600]'>Быстрый </span>вывод средств.</span></br>
               <span class='liSpan'><span class='text-[var(--green)] font-[600]'>Лояльная </span>служба поддержки.</span>`
      
            },
            {
               id:7,
               locale:'pt',
               text:`<span class='liSpan'>Registro<span class='text-[var(--green)] font-[600]'> simples.</span></span></br>
               <span class='liSpan'>Retirada<span class='text-[var(--green)] font-[600]'> rápida de fundos. </span>
               </span></br>
               <span class='liSpan'>Serviço de apoio ao cliente<span class='text-[var(--green)] font-[600]'> leal. </span></span>`
            },
            {
               id:8,
               locale:'bd',
               text:`<span><span class='text-[var(--green)] font-[600]'>সহজ  </span> রেজিস্ট্রেশন ও 
               <span class='text-[var(--green)] font-[600]'> দ্রুত  </span> টাকা উত্তোলন।
               <span class='text-[var(--green)] font-[600]'> বিশ্বস্ত  </span> সাপোর্ট টিম।</span>`
            }
            
         ],
         imgs:{
            f:{
               src:'/img/s9.png',
               w:162,
               h:162
            },
            s:{
               src:'/img/s10.png',
               w:130,
               h:130
            },
            t:{
               src:'/img/s11.png',
               w:235,
               h:235
            },
            fh:{
               src:'/img/s12.png',
               w:436,
               h:436
            }
         }
      },
      {
         id:2,
         name:'sport',
         title:[
            {
               id:1,
               locale:'uz',
               text:`Sport`
            },
            {
               id:2,
               locale:'fr',
               text:`Sport`
      
            },
            {
               id:3,
               locale:'en',
               text:`Sports`
      
            },
            {
               id:4,
               locale:'so',
               text:`Ciyaaro`
      
            },
            {
               id:5,
               locale:'ar',
               text:`رياضة`
      
            },
            {
               id:6,
               locale:'ru',
               text:`Спорт`
      
            },
            {
               id:7,
               locale:'pt',
               text:`Esporte`
      
            },
            {
               id:8,
               locale:'bd',
               text:`খেলাধুলা`
      
            }
            
         ],
         descr:[
            {
               id:1,
               locale:'uz',
               text:`<span>Bizning veb-saytimizda HD sifatli o'yinlarni tomosha qiling va eng ishonchli bukmekerdan <span class='text-[var(--green)] font-[600]'> birinchi depozitingiz uchun 1 300 000 UZS gacha </span> ega bo'ling</span>`

            },
            {
               id:2,
               locale:'fr',
               text:`<span>Suivez en direct les matchs en qualité HD sur notre site web et obtenez <span class='text-[var(--green)] font-[600]'> jusqu'à 60.000 Fr cfa sur votre premier dépôt, </span> du bookmaker le plus fiable.</span>`

      
            },
            {
               id:3,
               locale:'en',
               text:`<span>Watch HD quality sports broadcasts on our site and get<span class='text-[var(--green)] font-[600]'> 100$ on the first deposit </span> from the most reliable bookmaker</span>`

      
            },
            {
               id:4,
               locale:'so',
               text:`<span>Kadaawo ciyaaro tayadoodu sarayso websitekayaga oo <span class='text-[var(--green)] font-[600]'> ka hel boqol dollar lacag dhigashada </span> u horaysa shaxsameeyaha loogu kalsonida badan yahay.</span>`

      
            },
            {
               id:5,
               locale:'ar',
               text:`
               <div class="directionRtl ">
                  <span class=' text-[var(--green)] font-[600] mx-[6px]'> 100 دولار على إيداعك الأول من</span>
                  <span class="">  وكيل المراهنات الأكثر ولاءً</span>

                  <span>شاهد بث المباريات بجودة HD على موقعنا الإلكتروني واحصل على ما يصل إلى</span>

               </div>


               `
      
            },
            {
               id:6,
               locale:'ru',
               text:`<span>Смотри трансляции матчей в HD качестве на нашем сайте и получи <span class='text-[var(--green)] font-[600]'> до 100$ на первый депозит </span> от самого лояльного букмекера</span>`
      
            },
            {
               id:7,
               locale:'pt',
               text:`<span>Assista a transmissões de jogos 
               em qualidade HD em nosso site 
               e receba <span class='text-[var(--green)] font-[600]'> até$ 100 no primeiro 
               depósito </span> da casa de apostas 
               mais leal</span>`

      
            },
            {
               id:8,
               locale:'bd',
               text:`<span>আমাদের ওয়েবসাইটে খেলার সম্প্রচার দেখুন HD-তে এবং <span class='text-[var(--green)] font-[600]'> প্রথম ডিপোজিট এ ১০,০০০৳ </span>পর্যন্ত বোনাস বুঝে নিন।</span>`

      
            }
            
         ],
         imgs:{
            f:{
               src:'/img/s1.png',
               w:162,
               h:162
            },
            s:{
               src:'/img/s2.png',
               w:130,
               h:130
            },
            t:{
               src:'/img/s3.png',
               w:235,
               h:235
            },
            fh:{
               src:'/img/s4.png',
               w:436,
               h:436
            }
         }
      },
      {
         id:3,
         name:'casino',
         title:[
            {
               id:1,
               locale:'uz',
               text:`Kazino`
            },
            {
               id:2,
               locale:'fr',
               text:`Casino`
      
            },
            {
               id:3,
               locale:'en',
               text:`Casino`
      
            },
            {
               id:4,
               locale:'so',
               text:`Qamaarka`
      
            },
            {
               id:5,
               locale:'ar',
               text:`كازينو`
      
            },
            {
               id:6,
               locale:'ru',
               text:`Казино`
      
            },
            {
               id:7,
               locale:'pt',
               text:`Casino`
      
            },
            {
               id:8,
               locale:'bd',
               text:`ক্যাসিনো`
      
            }
            
         ],
         descr:[
            {
               id:1,
               locale:'uz',
               text:`<span>Biz   <span class='text-[var(--green)] font-[600]'> haqiqatdan ham o'ynashga va yutuqqa erishishingizga kafolat beramiz.</span> Bunga o'zingiz ishonch hosil qilishingiz mumkin</span>`

            },
            {
               id:2,
               locale:'fr',
               text:`<span>Nous  <span class='text-[var(--green)] font-[600]'> vous permettons de vraiment jouer et de gagner. </span> Assurez-vous de cela vous-même.</span>`

      
            },
            {
               id:3,
               locale:'en',
               text:`<span>You <span class='text-[var(--green)] font-[600]'> play </span> and you <span class='text-[var(--green)] font-[600]'> really win. </span> See for yourself</span>`

      
            },
            {
               id:4,
               locale:'so',
               text:`<span>Ciyaar <span class='text-[var(--green)] font-[600]'>adigoo guulaysana,</span> waad arki doonta</span>`

      
            },
            {
               id:5,
               locale:'ar',
               text:`
               <div class='directionRtl flex items-center'> 
               <p>انظر بنفسك</p>
               <p class='mx-[6px]'> نحن</p>
               <p class='text-[var(--green)] font-[600] '>حقا نسمح لك باللعب والفوز.</p>
               </div>
               `
      
            },
           
            {
               id:6,
               locale:'ru',
               text:`<span>Мы  <span class='text-[var(--green)] font-[600]'>реально даем играть и выигрывать.</span> Убедись в этом сам</span>`
      
            },
            {
               id:7,
               locale:'pt',
               text:`<span>Nós  <span class='text-[var(--green)] font-[600]'>realmente deixamos você 
               jogar e ganhar. </span> Veja por si mesmo</span>`

      
            },
            {
               id:8,
               locale:'bd',
               text:`<span> <span class='text-[var(--green)] font-[600]'> খেলুন  </span> এবং  <span class='text-[var(--green)] font-[600]'> জিতুন</span>, আপনি নিজেই দেখুন</span>`

      
            }
            
         ],
         imgs:{
            f:{
               src:'/img/s5.png',
               w:162,
               h:162
            },
            s:{
               src:'/img/s6.png',
               w:130,
               h:130
            },
            t:{
               src:'/img/s7.png',
               w:235,
               h:235
            },
            fh:{
               src:'/img/s8.png',
               w:436,
               h:436
            }
         }
      }
   ]
   
   


   export default slider

