
const meta = [
   {
      id:1,
      name:'uz',
      title:`Linebet kompaniyasi bilan sport oyinlariga pul tiking`,
      descr:"Ishonchli Linebet platformasida o'ynang! Bu yerda siz Live rejimida ham, o`yinlarda ham sport uchun ko'plab takliflarni topasiz. Bizning koeffitsientimiz  eng yuqori koeffitsientlardan biridir va bonuslar o'yiningizni yanada yorqinroq qiladi. Linebet bilan g`alaba qiling!"
   },
   {
      id:2,
      name:'fr',
      title:`Paris sportifs en ligne — société de paris Linebet.`,
      descr:`Jouez aux Jeux de hasard et d'argent sur la plateforme la plus fiable Linebet ! Vous trouverez ici un grand nombre de grilles de paris pour le sport et aussi pour le Live. Nos côtes sont parmi les plus élevées du marché, et le système de bonus rendra votre mise encore plus rentable. Gagnez avec Linebet !`
   },
   {
      id:3,
      name:'en',
      title:`Online sports betting - Linebet!`,
      descr:`Play games of chance on the most reliable platform -  Linebet! 
      Here you will find a large number of coefficients - both live and closing lines. Our odds are the highest on the market together with bonus system that will help you to gain more. Win together with Linebet!
      `
   },
   {
      id:4,
      name:'so',
      title:`Khamaarka ciyaaraha tooska ah - Linebet `,
      descr:`Ku ciyaar cayaaraha fursadaha madal lagu kalsoonaan karo - Linebet! 
      Halkan waxaad ka heli doontaa tiro badan oo isku-duwayaal ah - khadadka toos iyo kuwa xiritaanka labadaba. Faa'iidooyinkayagu waa kuwa ugu sarreeya suuqa oo ay weheliso nidaamka gunnada oo kaa caawin doona inaad wax badan hesho. 
       wada guuleysta Linebet!
      `
   },
   {
      id:5,
      name:'ar',
      title:`المراهنات الرياضية عبر الإنترنت - Linebet`,
      descr:`ألعب ألعاب الحظ على منصة موثوقة - Linebet
      ستجد هنا عددًا كبيرًا من الأحداث  - كل من الأحداث المباشرة و الأحداث المغلقة  . احتمالاتنا هي الأعلى في السوق جنبًا إلى جنب مع نظام المكافآت الذي سيساعدك على كسب المزيد. اربح مع Linebet!
      `
   },
   {
      id:6,
      name:'ru',
      title:`Ставки на спорт онлайн - букмекерская компания Linebet.`,
      descr:`Играйте в азартные игры на надежной платформе Linebet! У нас вы найдете большое количество котировок на спорт, как в режиме Live, так и по линии. Наши коэффициенты - одни из самых высоких на рынке, а система бонусов сделает вашу игру еще более выгодной. Побеждайте вместе с Linebet! `
   },
   {
      id:7,
      name:'pt',
      title:`Apostas esportivas online-Empresa de apostas online Line bet. `,
      descr:`Jogue os jogos de azar na plataforma confiável da Line bet! Aqui você encontrará um grande número de cotações de esportes, tanto ao vivo quanto on-line. As nossas probabilidades estão entre as mais altas do mercado e o nosso sistema de bónus torna o seu jogo ainda mais lucrativo. Ganhe com a Linebet!`
   },
   {
      id:8,
      name:'bd',
      title:`অনলাইন স্পোর্টস বেটিং - বাজি কোম্পানি Linebet`,
      descr:`নির্ভরযোগ্য Linebet প্ল্যাটফর্মে বাজি খেলুন ! এখানে আপনি লাইভ মোডে এবং লাইনে উভয় খেলার জন্য প্রচুর সংখ্যক অপশন  পাবেন। আমাদের অড্স রেট বেটিং  মার্কেটে  সর্বোচ্চ , এবং বোনাস সিস্টেম আপনার গেমটিকে আরও বেশি লাভ জনক করে তুলবে। Linebet এর সাথে জিতুন !`
   }
   
]

export default meta






