'use client'
import React,{useEffect} from 'react'
import Fly from '../fly/Fly'
import useWindowSize from '../../hooks/useWindowSize'
import welcome  from '../data/welcome'
import { createMarkup } from '../funcs/createMp';
import  notfound  from '../data/notfound'

const Welcome = ({locale,notFound}) => {
   const desk = useWindowSize()
   const handleClick = () =>{
      document.querySelector('#bonus')?.scrollIntoView({ behavior: "smooth"})
   }
   
   
   return (
      <section className={`container `}>
         
         <div className={`flex relative   flex-col ${notFound?'md:items-start items-center xl:justify-center xl:py-[120px] py-[70px]' : 'items-center justify-center md:py-[170px]'}  font-[500] bg-[var(--gray)] rounded-[40px] px-2 4xl:py-[219px]  h-auto ${notFound?'md:min-h-[600px] min-h-[450px]':'min-h-[600px]'} `}>
            <div className=' w-full h-full absolute top-0 left-0 overflow-hidden rounded-[40px]'>
               {
                  !notFound&&
                  <>
                  <div className={`absolute z-[2] top-[-30px] md:left-[25%] sm:left-[50%] left-[40%] welcomeImgT`}>
                  <Fly delay='0s' wid={119} hei={119} src={'/img/Dice1.png'}/>
                  </div>
                  <div className={`absolute z-[2] top-[-50px] md:left-[72%] sm:left-[50%] left-[30%] welcomeImgR`}>
                     <Fly delay='1s' wid={296} hei={296} src={'/img/d2.png'}/>
                  </div>
                  <div className={`absolute z-[2] xl:top-[70%] top-[60%] md:left-[15%] left-[-150px] welcomeImgB`}>
                     <Fly delay='2s' wid={332} hei={332} src={'/img/d3.png'}/>
                  </div>
                  <div className={`absolute z-[2] top-[44%] md:left-[8%] left-[-60px] welcomeImgL`}>
                     <Fly delay='3s' wid={103} hei={103} src={'/img/d4.png'}/>
                  </div>
                  <div className={`absolute z-[2] top-[50%] left-[70%]`}>
                     <Fly delay='2s' wid={88} hei={98} src={'/img/d4.png'}/>
                  </div>
                  </>
               }
               <div className='bgImgCnt'>

               </div>
               <img className={` bgImg ${notFound&&'scale-x-[-1]'} `} src="/img/graybg.png" alt="bg" />
               
               {notFound&&<img className={`notFoundImg md:opacity-100 opacity-70 absolute md:right-[-200px] right-0  md:bottom-[-260px] sm:bottom-[-150px] bottom-[-70px] z-[1] `}  src="/img/notf.png" alt="bg" />}
               
            </div>
            <h1 className={`${notFound&&'block xl:w-[65%] md:w-2/3 px-[30px] 4xl:text-left text-center'} relative z-[3]  uppercase ${locale==='pt'?'4xl:text-[72px] xl:text-[50px] md:text-[36px] text-[26px]':'4xl:text-[92px] xl:text-[70px] md:text-[56px] text-[36px]'} text-center max-w-[1000px]`}>
               <span dangerouslySetInnerHTML={createMarkup(notFound?notfound.title.find(item=>item.locale===locale)?.text??'':welcome.title.find(item=>item.locale===locale)?.text??'')}></span>
            </h1>
            {
               notFound&&
               <p className=' drop-shadowNF relative z-[3] 4xl:text-left text-center leading-[1.5] font-[300] 4xl:text-[20px] mt-[20px] text-[16px] block xl:w-[65%] md:w-2/3 px-[30px]'>
                  <span dangerouslySetInnerHTML={createMarkup(notfound.desc.find(item=>item.locale===locale)?.text??'')}></span>
               </p>
            }

            {
            !notFound&&
            <>
            <button style={{lineHeight:'1'}} onClick={handleClick} className={`relative z-[3] btn 4xl:text-[36px] xl:text-[28px] md:text-[24px] text-[20px] 4xl:mt-[49px] mt-[40px] text-white`}>
               {welcome.btn.find(item=>item.locale===locale)?.text}
            </button>

            <article className={`4xl:top-[10%] md:top-[10px] sm:top-[48px] top-[10px] bg-white flyObj font-[400] leading-normal absolute z-[2] rounded-[35px] rounded-bl-none px-[24px] py-[21px] flex items-center`}>
               <div className='relative md:mr-[20px] mr-[6px]' style={{width:desk.desk?108+'px':65+'px', height:desk.desk?108+'px':65+'px'}} >
                  <img  className='absolute' src="/img/w1.png" alt="w" />
               </div>
               <p dangerouslySetInnerHTML={createMarkup(welcome.left.find(item=>item.locale===locale)?.text??'')}>
               </p>
            </article>
            <article className={`xl:bottom-[10%] sm:bottom-[10%] bottom-[5%]  bg-white flyObj flyObjR leading-normal absolute z-[2] rounded-[35px] rounded-br-none px-[24px] py-[21px] flex items-center`}>
               <img className=' md:mr-[20px] mr-[6px]'  src="/img/w2.png" alt="w" />
               <p dangerouslySetInnerHTML={createMarkup(welcome.right.find(item=>item.locale===locale)?.text??'')}>
               </p>
            </article>
            </>
            
            }
         </div>
      </section>
   )
}

export default Welcome