import React from 'react'

const Langs = ({setLangF,lang,index,locale}) => {

   return (
      <li onClick={()=>setLangF(lang.name)} className={`${locale===lang.name&&'hidden'} transition-colors hover:bg-[var(--green-d)] border-r border-b border-[var(--green-l)] border-solid py-[10px] px-[2px] text-white flex items-center justify-center cursor-pointer uppercase w-1/2`}>
         <img className='mr-[10px] h-[16px]' width={21} height={16} src={lang.img} alt={lang.name} />
         {lang.name}
      </li>
   )
}

export default Langs