import React,{useEffect, useRef, useState} from 'react'
import { createMarkup } from '../funcs/createMp';
import slider from '../data/slider';

const Slider = ({locale}) => {
   const [prev,setPrev] = useState(1)
   const [active,setActive] = useState(2)
   const [next,setNext] = useState(3)

   const prevBtn = useRef(null)
   const nextBtn = useRef(null)
   // type T = keyof typeof slider;
   // const ind: T = locale as T


   const arrClick = (next)=>{
      let i = active
      if(next){
         i+=1

      }else{
         i-=1
      }
      if(i > slider.length){
         i = slider[0].id
      }
      if(i === 0){
         i = slider[slider.length-1].id
      }

      if(slider.find(item=>item.id === i)){
         let p = i - 1
         let n = i + 1
         if(p > slider.length){
            p = slider[0].id
         }
         if(p === 0){
            p = slider[slider.length-1].id
         }
         if(n > slider.length){
            n = slider[0].id
         }
         if(n === 0){
            n = slider[slider.length-1].id
         }
         setPrev(p)
         setNext(n)
         setActive(i)
      }
   }


   return (
      <section  className={`container slider-cnt mtop  md:pt-[70px] relative flex items-center justify-center md:pb-[70px] pb-[40px]`}>
         <div className='slider'>

         <button onClick={()=>arrClick(false)} ref={prevBtn} className={` absolute z-[3] md:top-1/2 top-auto md:bottom-auto bottom-[20px] md:-translate-y-1/2 md:translate-x-0 translate-x-[-50px] translate-y-full left-1/2 xl:left-1/4 md:left-[10%] md:p-[20px] p-[10px] overflow-hidden md:rounded-[20px] rounded-[8px] bg-[#d2d1d1] opacity-70 md:hover:opacity-100 md:hover:bg-[var(--green)] transition-all`}>
            <img className={`rotate-180 md:max-w-[34px] md:max-h-[34px] max-w-[20px] max-h-[20px] w-auto`} src="/img/arrsl.png" alt="left" />
         </button>
         {
            slider.map((item,index)=>(
                  // active===item.id&&
                  <article style={{transform:`${active===item.id?`scale(1) translateX(${item.id===1?'100%':item.id===2?'0':'-100%'})`:prev===item.id?` translateX(${item.id===1?'0%':item.id===2?'-100%':'-200%'}`:` translateX(${item.id===1?'200%':item.id===2?'100%':'0%'}`}`}} key={item.id} className={`${active===item.id&&'active'}   slide rounded-[40px]`}>
                     <div style={{height:'100%'}} className={`bg-white flex items-center justify-center rounded-[40px] slideCnt-cnt ${next===item.id&&'next'} ${prev===item.id&&'prev'} relative font-[300] leading-[1.5]  `}>

                        <div  className={`slideCnt w-full md:px-[50px] px-[36px] xl:py-[70px] md:py-[46px] py-[30px]`}>
                           {/* el = {prev===item.id?'prev':next===item.id?'next':'active'}, id={item.id}, index={index} */}
                           <div className={`hover:translate-x-[20px] hover:translate-y-[20px] duration-700 transition-transform md:block hidden absolute z-[2] ${item.id===2?'top-[-120px] left-[20px]':item.id===3?'top-[-30px] left-[-30px]':'top-[-45px] left-[-70px]'}  `}>
                              <img className={'bonusImg'} alt={item.name} width={item.id===2?162:item.id===3?82:125}  src={item.imgs.f.src}/>
                           </div>
                           <div className={`hover:translate-x-[-20px] hover:translate-y-[-20px]  duration-700 transition-transform absolute z-[2] ${item.id===2?'top-[-60px] xl:right-[40px] right-[-30px]':item.id===3?'md:top-[-110px] top-[-30px] md:right-[-70px] right-[0px]':'md:top-[-155px] top-[-55px] md:right-[-120px] right-[-50px]'}  `}>
                              <img style={{animationDelay:'0.5s'}} className={`bonusImg md:max-w-none md:max-h-none  ${item.id===2?'':item.id===3?'max-w-[120px] max-h-[148px]':'max-w-[143px] max-h-[143px]'}`} alt={item.name}  src={item.imgs.s.src}/>
                           </div>
                           <div className={`hover:translate-x-[20px] hover:translate-y-[-20px] duration-700 transition-transform absolute z-[2] ${item.id===2?'md:bottom-[-140px] bottom-[-50px] md:left-[-140px] left-[-20px]':item.id===3?'md:bottom-[-150px] bottom-[-50px] md:left-[-150px] left-[-70px]':'md:bottom-[-150px] bottom-[-40px] md:left-[-160px] left-[-40px]'}  `}>
                              <img style={{animationDelay:'1.5s'}}  className={`bonusImg md:max-w-none md:max-h-none  ${item.id===2?'max-w-[80px] max-h-[80px]':item.id===3?'max-w-[179px] max-h-[101px]':'max-w-[140px] max-h-[78px]'}`} alt={item.name}  src={item.imgs.t.src}/>
                           </div>
                           <div className={`hover:translate-x-[-20px] hover:translate-y-[20px] duration-700 transition-transform absolute z-[2] ${item.id===2?'md:bottom-[-220px] bottom-[-80px]  md:right-[-220px] right-[-100px]':item.id===3?'md:bottom-[-30px] bottom-[-50px] right-[-30px]':'bottom-[-60px] md:right-[-50px] right-[-40px]'}  `}>
                              <img style={{animationDelay:'0s'}}  className={`bonusImg md:max-w-none md:max-h-none  ${item.id===2?'max-w-[200px] max-h-[200px]':item.id===3?'':''}`} alt={item.name} width={item.id===2?436:item.id===3?82:117}  src={item.imgs.fh.src}/>
                           </div>
                           <h2 className={`relative z-[3] md:text-[48px] uppercase leading-[1] text-[36px] md:mb-[40px] mb-[20px] font-[500]`}>
                              {item.title.find(item=>item.locale===locale)?.text}
                           </h2>
                           <p className='relative z-[3]' dangerouslySetInnerHTML={createMarkup(item.descr.find(item=>item.locale===locale)?.text??'')}>
                           </p>
                        </div>
                     </div>

                  </article>
               ))
            }
         
         <button onClick={()=>arrClick(true)} ref={nextBtn} className={` absolute z-[3] md:top-1/2 top-auto md:bottom-auto bottom-[20px] md:-translate-y-1/2 md:translate-x-0 translate-x-[50px] translate-y-full xl:right-1/4 md:right-[10%] right-1/2 md:p-[20px] p-[10px] overflow-hidden md:rounded-[20px] rounded-[8px] bg-[#d2d1d1] opacity-70 md:hover:opacity-100 md:hover:bg-[var(--green)] transition-all`}>
            <img className='md:max-w-[34px] md:max-h-[34px] max-w-[20px] max-h-[20px]'   src="/img/arrsl.png" alt="right" />
         </button>
         </div>

      </section>
   )
}

export default Slider