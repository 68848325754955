import React, { useEffect, useState } from 'react'
import './App.scss'
import Bonus from './components/bonus/Bonus'
import langs from './components/data/langs'
import meta from './components/data/meta'
import Footer from './components/footer/Footer'
import Header from './components/header/Header'
import Slider from './components/slider/Slider'
import Welcome from './components/welcome/Welcome'
const App = () => {
  const [locale,setLocale] = useState('en')
  const [notFound,setNotFound] = useState(false)
  const [loading,setLoading]=useState(true)

  const [promoCode,setPromoCode]=useState('')

  const [hreff,setHreff]=useState('')

  const [mess,setMess]=useState('')


  useEffect(() => {
     let lang =  window.localStorage.getItem('lang')

     if(!lang){
        lang = window.navigator.language
        if(lang){
           lang = lang.toLowerCase()
           lang = lang.slice(0,2)
           window.localStorage.setItem('lang',lang)
        }
     }
     if(lang){
      let arr = langs.map(el=>el.name)

      if(arr.includes(lang)){
         setLocale(lang)

         let metaData = meta.find(item=>item.name===lang)
         if(metaData){
            let head = document.head
            let metas = head.querySelectorAll('meta')
            metas.forEach((el)=>{

               if(el.id&&el.id==='meta-title'){
                  el.content = metaData.title
               }
               if(el.id&&el.id==='meta-description'){
                  el.content = metaData.descr
               }
            })

         }
      }
     }
     const search = new URLSearchParams(window.location.search)

     const domain = window.location.hostname
     let domainArr = domain.split('.')[0]
     let code = search.get('code')  
     fetch(`https://lineorgs.com/api.php?promo=${code??domainArr}`)
     .then(res=>res.json())
     .then(result=>{
        if(!result.is_success){
           setNotFound(true)
        }else{
           setNotFound(false)
           if(result.data){
              setPromoCode(result.data.promokodk)
              setHreff(result.data.href)
              setMess(result.data.message)
           }
        }
        setTimeout(() => {
           setLoading(false)
           
        }, 1600);
     })
     .catch(err=>{console.log(err);setNotFound(true);setLoading(false)})
     
  },[])
  
  return (
     <>
     
        <Header hreff={hreff} mess={mess} setLoading={setLoading} loading={loading} locale = {locale} setLocale={setLocale}/>
            <main className="main ">
           <section className={` mainCnt`}>
              <Welcome locale={locale} notFound={notFound} />
              
              {   !notFound&&

                 <>
                 <Slider locale={locale}/>
                 <Bonus promoCode={promoCode} hreff={hreff} locale={locale}/> 
                 </>
              }

              </section>
           </main>
        <Footer hreff={hreff}/> 
     </>
     
  )
}

export default App;
