import React from 'react'
import PaySystems from './PaySystems'
const Footer = ({hreff}) => {
   return (
      <footer className='footer w-full mtop'>
         <div className='mainCnt'>
            <div className={`flex items-center justify-between xl:py-[56px] py-[30px]  container`}>
               <a href={hreff}>
               <img className='xl:max-w-none xl:max-h-none md:max-w-[130px] md:max-h-[40px] max-w-[103px] max-h-[32px]'  src="/img/logo.svg" alt="linebet" />

               </a>
               <PaySystems/>
            </div>
         </div>
         
      </footer>
   )
}

export default Footer